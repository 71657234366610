import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const LANGUAGE_CODES = ['en', 'it', 'fr', 'de', 'es', 'ja', 'nb', 'nl', 'pl', 'sv', 'da', 'gr'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: navigator.language,
    resources: LANGUAGE_CODES.reduce((lngMap, langCode) => {
      lngMap[langCode] = {
        translations: require(`./transalation/${langCode}.json`),
      };

      return lngMap;
    }, {}),
    ns: ['translations'],
    defaultNS: 'translations',
  });

i18n.languages = LANGUAGE_CODES;

export default i18n;
